import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageHeaderText="DevDuck Games" pageHeaderImage="meadow.png">
    <SEO title="Home" />
    <h2 style={{textAlign:"center", fontSize:"3em", marginTop:"1rem", marginBottom:"2rem"}}>Welcome to DevDuck Games.</h2>
    <p style={{textAlign:"center", maxWidth:"960px", margin:"0 auto"}}>
      I'm a solo indie developer making <Link to="/apps">games and apps</Link> that I'm passionate about.  I also create <a href="https://youtube.com/c/devduck" target="_blank" rel="noopener noreferrer">YouTube</a> content to inspire others to pursue their own dream projects and show that it's possible while juggling the responsibilities of a full time job and other hobbies.
    </p>
    <p style={{textAlign: "center", maxWidth: "960px", margin: "0 auto", marginTop: "1rem", marginBottom: "2rem"}}>
      Come learn about the projects I'm working on and how I do it!  If you want a place to start, check out my Day in the Life video.  Thanks for swinging by.
    </p>
    <div style={{width:"960"}}>
    <iframe width="100%" height="540" title="home-dayInTheLife" src="https://www.youtube.com/embed/_I51sqsDR-M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </Layout>
)

export default IndexPage
